.preview{
  position: relative;
  margin-bottom: 50px;
  height: 45vw;
  max-width: 900px;
  cursor: pointer;
  user-select: none;
  &:nth-of-type(even){
    right: -45%;
  }
  &:last-child{
    margin-bottom: 0;
  }
  &.type-2{
    .preview__shadow{
      left: 18%;
    }
  }
  &__foreground {
    pointer-events: none;
  }
  img{
    max-width: 100%;
  }
  &__inner{
    z-index: 2;
  }
  &__shadow{
    position: relative;
    z-index: -1;
    transform: translateZ(-1px);
    max-width: 100%;
  }
}

.preview-1{
  .preview__foreground{
    &> div:last-child{
      width: 60%;
    }
  }
}

.preview-2{
  .preview__foreground{
     &> div:last-child{
       width: 25%;
     }
  }
}

.preview-6{
  .preview__foreground{
    &> div:first-child{
      img{
        width: 50%;
      }
    }
    &> div:last-child{
      img{
        width: 55%;
      }
    }
  }
}

.preview-7{
  .preview__foreground{
    img{
      width: 40%;
    }
  }
}

.preview-8{
  .preview__foreground{
    img{
      width: 80%;
    }
  }
}

.preview-9{
  .preview__foreground{
    img{
      width: 45%;
    }
  }
}

.preview-10{
  .preview__foreground{
    img{
      width: 45%;
    }
  }
}

.dots{
  display: block;
}

.dots-large{
  display: none;
}

@media(min-width: 2600px){
  .previews{
    padding: 0 200px;
  }

  .dots{
    display: none;
  }

  .dots-large{
    display: block;
  }

  .preview-1 {
    .preview__foreground {
      & > div:last-child {
        top: 10%;
        left: 0;
        img {
          width: 85%;
        }
      }
    }
  }

  .preview-2 {
    .preview__foreground {
      & > div:first-child {
        left: 40%;
        top: 10%;
        img {
          width: 130%;
        }
      }
    }

    .preview__foreground {
      & > div:last-child {
        width: unset;
        top: 15%;
        left: 15%;
        img{
          width: revert;
        }
      }
    }
  }

  .preview-3{
    .preview__foreground{
      & > div:first-child{
        top: 13%;
        left: 15%;
        img{
          width: 110%;
        }
      }
      & > div:last-child{
        left: 35%;
        top: 10%;
        img{
          width: 120%;
        }
      }
    }
  }

  .preview-4{
    .preview__foreground{
      div{
        left: 38%;
        top: 15%;
        img{
          width: 140%;
        }
      }
    }
  }


  .preview-5{
    .preview__foreground{
      div{
        top: 20%;
        left: 10%;
        img{
          width: 130%;
        }
      }
    }
  }

  .preview-6{
    .preview__foreground{
      &> div:first-child{
        top: -3%;
        left: 16%;
        img{
          width: unset;
        }
      }
      &> div:last-child{
        top: 3%;
        left: 5%;
        img{
          width: 75%;
        }
      }
    }
  }

  .preview-7{
    .preview__foreground{
      &> div:first-child{
        top: -3%;
        left: 26%;
        img{
          width: 80%;
        }
      }
    }
  }

  .preview-8{
    .preview__foreground{
      &> div:first-child{
        img{
          width: 140%;
        }
      }
    }
  }

  .preview-9{
  .preview__foreground{
     & > div:first-child{
      right: 30%;
        img{
          width: 80%;
        }
      }
  }
}

.preview-10{
  .preview__foreground{
    & > div:first-child{
      right: 30%;
        img{
          width: 80%;
        }
      }
  }
}

  .preview{
    max-width: 1700px;
    height: 45vw;
    overflow: visible;
    img{
      max-width: unset;
    }
    & > div{
      width: 100%;
    }
    .preview__shadow, .preview__inner{
      img{
        width: 100%;
      }
    }
    &:nth-of-type(even){
      right: -50%;
    }
  }
}

@media(max-width: 1600px){
  .previews{
    padding: 0 50px;
  }
  .preview{
    height: 40vw;
    max-width: 700px;
    & > div{
      width: 100%;
    }
    .preview__shadow, .preview__inner{
      img{
        width: 100%;
      }
    }
    &:nth-of-type(even){
      right: -46%;
    }
  }

  .preview-1 {
    .preview__foreground {
      & > div:first-child {
        top: 15%;
        left: 5%;
        img {
          width: 75%;
        }
      }
    }
  }

  .preview-2 {
    .preview__foreground {
      & > div:first-child {
        img {
          width: 75%;
        }
      }
    }
  }

  .preview-3{
    .preview__foreground {
      & > div:first-child {
        top: 10%;
        img {
          width: 60%;
        }
      }
      & > div:last-child {
        top: 10%;
        left: -10%;
        img {
          width: 55%;
        }
      }
    }
  }

  .preview-4{
    .preview__foreground {
      & > div:first-child {
        top: 10%;
        left: -10%;
        img {
          width: 60%;
        }
      }
    }
  }

  .preview-5{
    .preview__foreground {
      & > div {
        top: 10%;
        left: 2%;
        img {
          width: 65%;
        }
      }
    }
  }

  .preview-6{
    .preview__foreground{
      &> div:first-child{
        top: -9%;
        left: -12%;
        img{
          width: 45%;
        }
      }
      &> div:last-child{
        top: -6%;
        img{
          width: 60%;
        }
      }
    }
  }

  .preview-7{
    .preview__foreground {
      & > div {
        top: -5%;
        left: -7%;
        img {
          width: 35%;
        }
      }
    }
  }

  .preview-8{
    .preview__foreground {
      & > div {
        img {
          width: 70%;
        }
      }
    }
  }

.preview-10{
  .preview__foreground{
    & > div:first-child{
      right: 30%;
      }
  }
}
}

@media(max-width: 1200px){
  .previews{
    padding: 0 30px;
    overflow: visible;
  }

  .preview{
    overflow: visible;
    height: 82vw;
    width: 100%;
    max-width: unset;
    &:nth-of-type(even) {
      right: unset;
    }
  }
}

@media (max-width: 600px) {
  .previews{
    padding: 0 20px;
  }

  .preview{
    height: 90vw;
    &:last-child{
      height: 70vw;
    }
  }

  .preview-2{
    .preview__foreground{
      & > div:first-child{
        left: -110%;
        img{
          width: 40%;
        }
      }
    }
  }

  .preview-3{
    .preview__foreground{
      & > div:first-child{
        top: -7%;
        width: 35%;
      }
      & > div:last-child{
        left: -85%;
        top: 3%;
        img{
          width: 40%;
        }
      }
    }
  }

  .preview-4{
    .preview__foreground{
      & > div:first-child{
        left: -95%;
        img{
          width: 35%;
        }
      }
    }
  }

  .preview-5{
    .preview__foreground{
      & > div:first-child{
        left: -13%;
        top: -2%;
        img{
          width: 40%;
        }
      }
    }
  }

  .preview-6{
    .preview__foreground{
      &> div:first-child{
        top: -36%;
        left: -78%;
        img{
          width: 30%;
        }
      }
      &> div:last-child{
          top: -9%;
          left: -6%;
      }
    }
  }

  .preview-7{
    .preview__foreground{
      & > div{
        left: unset;
        right: 20%;
        top: -2%;
        img{
          width: 40%;
        }
      }
    }
  }

  .preview-8{
    .preview__foreground{
      & > div{
        img{
          width: 55%;
        }
      }
    }
  }
}