 .Services-title{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Services{
    background-image: url(bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 2134px;
    margin-bottom: 60px;

    @media (min-width: 2560px) {
        background-image: url(bg_bigger_2560.jpg);
    }
    
    @media (max-width: 1440px) {
        height: unset;
    }
    @media (max-width: 2580px) {
        height: unset;
    }
    
    .content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        max-width: 1628px;
        
        .titleKey{
            margin-bottom: 60px;
        }

        @media (max-width: 1024px) {
            .row:nth-child(2n){
                flex-direction: column-reverse;
            }
        }
        .row{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 1024px) {
                flex-direction: column;
                justify-content: center;
            }
            
            &.last-service{
                align-items: flex-end;
                @media (max-width: 1024px) {
                    align-items: inherit;
                }
            }
            img{
                @media (max-width: 1920px) {
                    width: 620px;
                }
                @media (max-width: 1440px) {
                    width: 500px;
                }
                @media (max-width: 450px) {
                    width: 400px;
                }
            }
        }
        
    }
}