.Cookies-modal{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  max-height: 104px;
  padding: 34px;
  display: none;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(240deg, #5156CD 13.04%, #C754BB 45.73%, #FCBE77 76.72%);

  &.active{
    display: flex;
  }

  @media (max-width: 600px) {
    padding: 24px 15px;
    max-height: unset;
  }

  .Cookies-content{
    margin-top: -3%;
    display: flex;
    align-items: center;
    gap: 60px;
    padding: 15px 60px 15px 120px;
    border-radius: 30px;
    background: rgba(240, 242, 246, 0.10);
    box-shadow: 0 30px 60px 0 rgba(32, 9, 81, 0.15);
    backdrop-filter: blur(17.5px);

    @media(max-width: 1024px){
      margin-top: -5%;
      padding: 15px 30px 15px 60px;
    }

    @media(max-width: 850px){
      gap: 25px;
      padding: 15px 30px;
    }

    @media(max-width: 600px) {
      margin-top: -20%;
      gap: 15px;
      flex-direction: column;
    }

    p{
      font-size: 26px;
      line-height: 32px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      margin: 0;

      @media (max-width: 1024px) {
        font-size: 22px;
        line-height: 26px;
      }

      @media(max-width: 850px) {
        font-size: 16px;
        line-height: 22px;
      }

      @media(max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    #acceptCookies {
      appearance: none;
      border-radius: 50px;
      border: none;
      padding: 20px 60px;
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      box-shadow: 0 15px 30px 0 rgba(32, 9, 81, 0.15);
      cursor: pointer;
      background-color: #fff;
      span {
        -webkit-text-fill-color: transparent;
        background: linear-gradient(39deg, #6A3093 3.13%, #A044FF 96.04%) #fff;
        background-clip: text;
      }

      @media(max-width: 1024px) {
        padding: 20px 40px;
        font-size: 18px;
        line-height: 24px;
      }

      @media(max-width: 850px) {
        font-size: 14px;
        line-height: 22px;
      }

      @media(max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
        padding: 15px 30px;
      }
    }
  }

  #closeCookies{
    appearance: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
  }

  .Macaroon {
    position: absolute;
    z-index: -1;
    pointer-events: none;
    bottom: 0;

    &.purple {
      left: 16%;

      @media(max-width: 1024px) {
        left: 0;
      }

      @media(max-width: 600px) {
        width: 140px;
        left: -9%;
      }
    }

    &.pink {
      left: 10%;
      bottom: 10%;

      @media(max-width: 1024px) {
        left: -10%;
      }

      @media(max-width: 600px) {
        bottom: 0;
        left: -10%;
        width: 140px;
      }
    }
  }
}