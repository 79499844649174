.TeamOrbit{
    margin-top: 30px;
    width: 800px;
    height: 800px;
    position: relative;
    background-image: url(orbits.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    
    @media (max-width: 900px) {
        width: calc(100vw - 100px);
        height: calc(100vw - 100px);
    }
    @media (max-width: 450px) {
        width: calc(100vw - 30px);
        height: calc(100vw - 30px);
    }
}