.ChatWithUs{
    position: fixed;
    right: 70px;
    bottom: 50px;
    z-index: 3;
    
    .container{
        position: relative;
    }
    .opened{
        .menu-button {
            background-image: url(chat-close.png);
        }
        .menu-item.wa {
            transform: rotate(-30deg) translateY(-105px) rotate(30deg);
            transition-delay: 0.2s;
        }
        .menu-item.telegram {
            transform: rotate(30deg) translateY(-105px) rotate(-30deg);
            transition-delay: 0.1s;
        }
    }
    
    .menu-button{
        width: 90px;
        height: 90px;
        position: relative;
        cursor: pointer;
        border-radius: 50%;
        background-image: url(chat.png);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
        z-index: 4;
        transition-delay: 0.3s;
      
        &:hover + .rounded-text{
           transform: rotate(90deg);
        }
    }
    .rounded-text{
        position: absolute;
        width: 150px;
        height: 150px;
        top: -30px;
        left: -30px;
        z-index: 2;
        transition: transform 500ms linear;
    }

    .menu-item {
        width: 60px;
        height: 59px;
        position: absolute;
        top: 15px;
        left: 15px;
        border-radius: 50%;
        background-position: top;
        background-size: cover;
        transform: translate(0px, 0px);
        z-index: -2;
        transition: .5s;
        box-shadow: rgb(38, 57, 77) 0px 10px 20px -10px;
        :hover{
            opacity: 0.5;
            box-shadow: 0 5px 10px black;
        }
        &.wa{
            background-image: url(wa.png);
        }
        &.telegram{
            background-image: url(telegram.png);
        }
    }

    @media (max-width: 600px) {
        right: 20px;
        bottom: 20px;
        .opened {
            .menu-item.wa {
                transform: rotate(-20deg) translateY(-70px) rotate(20deg);
            }
            .menu-item.telegram {
                transform: rotate(20deg) translateY(-70px) rotate(-20deg);
            }
        }
        .menu-button {
            width: 60px;
            height: 60px;
        }
        .rounded-text{
            width: 100px;
            height: 100px;
            top: -20px;
            left: -20px;
        }
        .menu-item {
            width: 40px;
            height: 39px;
            top: 10px;
            left: 10px;
        }
    }
}