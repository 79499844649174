.OurContacts{
    background-image: url(bottomBG.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 1450px;
    overflow: hidden;

    @media (min-width: 2560px) {
        background-image: url(bottomBG_bigger_2560.jpg);
        background-size: contain;
    }

    @media (max-width: 768px) {
        height: unset;
        padding-bottom: 140px;
    }
    
    .content{
        max-width: 1415px;
        padding-bottom: 30px;
        @media (max-width: 450px) {
            padding: 0 14px;
        }
        .content-form{
            width: 100%;
            background: rgba(240, 242, 246, 0.5);
            box-shadow: 0px 30px 60px rgba(32, 9, 81, 0.15);
            backdrop-filter: blur(100px);
            border-radius: 30px;
            padding: 60px 120px;
            margin-top: 100px;

            @media (max-width: 1440px) {
                padding: 60px;
            }
            @media (max-width: 768px) {
                padding: 30px;
                height: unset;
                margin-bottom: 30px;
            }
            @media (max-width: 450px) {
                padding: 30px 14px 30px 14px;
            }
            
            .underTitleText{
                font-size: 26px;
                line-height: 40px;
                color: #546E7A;
                margin-bottom: 60px;
                max-width: 730px;
                
                @media (max-width: 1440px) {
                    font-size: 20px;
                    line-height: 30px;
                    max-width: unset;
                }
                @media (max-width: 768px) {
                    margin-bottom: 30px;
                }
            }
            .contactForm{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-size: 20px;

                @media (max-width: 768px) {
                    margin-bottom: 0;
                }

                .inputFields{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 30px;
                    @media (max-width: 768px) {
                        margin-bottom: 15px;
                    }

                    @media (max-width: 768px) {
                        flex-direction: column;
                    }

                    .Input + .Input{
                        margin-left: 30px;
                        @media (max-width: 768px) {
                            margin-left: 0;
                            margin-top: 15px;
                        }
                    }
                    input[type='checkbox']{
                        min-width: 30px;
                        min-height: 30px;
                        margin-right: 15px;
                    }
                    &.forCheckbox{
                        line-height: 34px;
                        color: #546E7A;
                        text-align: center;
                        @media (max-width: 768px) {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            text-align: left;
                        }
                    }
                }
                button{
                    cursor: pointer;
                    font-size: 20px;
                    line-height: 28px;
                    padding: 20px 60px;
                    background: linear-gradient(44.12deg, #E199D8 -9.02%, #B891E2 55.32%, #D4AEE4 121.02%);
                    box-shadow: 0px 15px 30px rgba(32, 9, 81, 0.15);
                    border: none;
                    border-radius: 50px;;
                    color: #FFFFFF;
                    @media (max-width: 768px) {
                        width: 100%;
                    }
                    &:hover{
                        background: linear-gradient(44.12deg, #CD6FC1 -9.02%, #9765CD 55.32%, #BB87D1 121.02%);
                    }
                }
            }
        }
        .SideMenuFooter{
            position: unset;
            transform: unset;
            padding-left: 0;
            padding-right: 0;
        }
        .all-right{
            font-size: 12px;
            line-height: 21px;
            text-align: center;
            color: #546E7A;

            span + span{
                margin-left: 20px;
            }
        }

        .animated-image-back {
            top: 0;
            left: 0;
            margin-left: 50px;
            margin-top: -100px;
            position: absolute;
            z-index: 0;

            animation: do-levitation-back 1.2s alternate ease-in-out infinite;

            @media (max-width: 1440px) {
                width: 100%;
            }
        }

        .animated-image-front {
            top: 0;
            right: 0;
            margin-right: 130px;
            margin-top: -50px;
            position: absolute;
            z-index: 1;

            animation: do-levitation-front 1.4s alternate ease-in-out infinite;

            @media (max-width: 1440px) {
                width: 25%;
                margin-right: 90px;
            }
        }

        @keyframes do-levitation-back {
            0% { transform: translate(0, 0); }
            100% { transform: translate(0, 1em); }
        }

        @keyframes do-levitation-front {
            0% { transform: translate(0, 0); }
            100% { transform: translate(0, 2em); }
        }
    }
}