.OurClients{
    margin-bottom: 30px;
    width: 100%;
    overflow: hidden;
    
    .content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media (max-width: 450px) {
            .title {
                text-align: center;
            }
        }
        .underTitleText{
            text-align: center;
            color: #546E7A;
            margin-bottom: 60px;
            > span{
                display: block;
            }
            @media (max-width: 450px) {
                > span{
                    display: unset;
                }
            }
        }
        .text-gradient{
            background: -webkit-linear-gradient(250deg, #B891E2 40.13%, #E199D8 100.04%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}