.Testimonial{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    width: 554px;
    height: 344px;
    background: #FFFFFF;
    border: 1px solid #F0F2F6;
    border-radius: 15px;
    margin: 0 15px;
    
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;

    @media (max-width: 1024px) {
        width: 400px;
        height: 420px;
    }
    @media (max-width: 450px) {
        width: 275px;
        height: 420px;
        padding: 15px;
    }

    .user{
        display: flex;
        flex-direction: row;
        align-items: center;
        img{
            margin-right: 28px;
        }
        
        .text{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 18px;
            line-height: 24px;
            color: #546E7A;
            @media (max-width: 450px) {
                font-size: 16px;
                line-height: 23px;
            }
        }
    }
    .text{
        font-size: 20px;
        line-height: 28px;
        @media (max-width: 450px) {
            font-size: 16px;
            line-height: 23px;
        }
    }
    
}