.TextElement{
    max-width: 578px;
    
    @media (max-width: 1024px) {
        margin-bottom: 30px;
        max-width: unset;
    }

    @media (max-width: 768px) {
        line-height: 27px;
    }
    
    .forTitle{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        .title{
            padding-right: 20px;
            margin-bottom: 0;
        }
    }
    .text + .text{
        margin-top: 40px;
    }
    .text{
        color: #546E7A;
    }
}