.AboutUs-background{
    right: 0;
    margin-top: -200px;
    position: absolute;
    z-index: 0;

    @media (max-width: 1440px) {
        height: 700px;
    }
    @media (max-width: 768px) {
        height: unset;
    }
}

.AboutUs{
    margin-bottom: 120px;
    
    .content{
        max-width: 1628px;
        @media (max-width: 768px) {
            padding-top: 160px;
        }
        > .forTitle{
            max-width: 820px;
            margin-bottom: 20px;
            .underTitleText{ 
                color: #546E7A;
            }
            
            @media (max-width: 1550px) {
                max-width: 600px;
            }
            @media (max-width: 1170px) {
                max-width: 50%;
            }
            @media (max-width: 860px) {
                max-width: 56%;
            }
            @media (max-width: 768px) {
                max-width: 100%;
            }
        }
        .columns{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            
            .right{
                margin-left: 30px;
            }

            .WeAre + .WeAre{
                margin-top: 30px;
                @media (max-width: 768px) {
                    margin-top: 0;
                    margin-left: 30px;
                }
            }
        }
        .animated-image {
            width: 702px;
            right: 0;
            top: 0;
            margin-top: -200px;
            margin-right: -50px;
            position: absolute;
            
            @media (max-width: 1800px) {
                top: 30px;
                right: 90px;
                width: 585px;
            }

            @media (max-width: 1440px) {
                top: 94px;
                right: 90px;
                width: 426px;
            }

            @media (max-width: 970px) {
                top: 152px;
                right: 70px;
                width: 320px;
            }

            @media (max-width: 768px) {
                top: 60px;
                right: 70px;
                width: 360px;
            }
            @media (max-width: 450px) {
                top: 60px;
                right: 60px;
                width: 330px;
            }
        }
        @keyframes do-levitation {
            0% {
                transform: translate(0, 0);
            }
            100% {
                transform: translate(0, 1em);
            }
        }
        .levitate {
            animation: do-levitation 1.2s alternate ease-in-out infinite;
        }
    }

    .InfinityCarouselStaticWidth{
        .carousel-track{
            padding: 0 30px;
        }
        .WeAre + .WeAre{
            margin-top: 30px;
            @media (max-width: 768px) {
                margin-top: 0;
                margin-left: 30px;
            }
        }
    }
}