body.comic-modal-active{
  overflow: hidden;

  .Modal-overlay{
    background: linear-gradient(107.59deg, rgba(0, 0, 0, 0.5) 0.23%, rgba(69, 0, 98, 0.5) 101.18%);
    backdrop-filter: blur(30px);
    padding: 50px;
  }

  .Modal-content{
    height: 100%;
  }

  .chapter{
    position: relative;
    overflow: auto;
    height: 100%;
    max-width: 100vw;
    background-color: #fff;
    user-select: none;
    &-img{
      width: 100%;
    }
    &-close{
      position: absolute;
      z-index: 101;
      background-color: #fff;
      border-radius: 50%;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      right: -1%;
      top: -2%;
      cursor: pointer;
      path{
        transition: .15s fill;
      }
      &:hover  path{
        fill: #D4AEE4;
      }
    }
  }

  figure {
    position: relative;
    display: inline-block;
    width: auto;
    background-position: 50% 50%;
    background-color: #fff;
    margin: 0;
    overflow: hidden;

    &:before {
      content: "";
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
      z-index: 1;
    }

    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 5px solid transparent;
      border-top-color: #333;
      border-right-color: #333;
      border-bottom-color: #333;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
      z-index: 2;
    }

    &.loaded {
      min-height: auto;

      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 0;
      }
    }
  }
}

@media (min-width: 2600px) {
  body.comic-modal-active{
    .Modal-overlay{
      padding: 100px 50px;
    }
    .chapter{
      width: auto;
      figure{
        max-height: 100%;
        height: 100%;
        img{
          height: 100%;
        }
      }
      &-close{
        width: 80px;
        height: 80px;
      }
    }
  }
}

@media(max-width: 1600px) {
  body.comic-modal-active .chapter{
    max-width: unset;
    figure, figure > img{
      height: 100%;
    }
  }
}

@media(max-width: 1200px) {
  body.comic-modal-active .Modal-overlay{
    padding: 50px 30px;
  }
}

@media (max-width: 600px) {
  body.comic-modal-active .Modal-content{
    height: auto;
  }
}