.Modal-content {
    outline: none;
    background: rgba(240, 242, 246, 0.5);
    box-shadow: 0 30px 60px rgba(32, 9, 81, 0.15);
    backdrop-filter: blur(100px);
    border: unset;
    border-radius: 30px;
    padding: 0;
}
.Modal-overlay{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}