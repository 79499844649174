.Slider {
    overflow: hidden;
    height: max-content;

    .carousel-track{
        width: max-content;
        display: flex;
        flex-direction: row;
    }
    .dots{
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .dot{
            width: 14px;
            height: 14px;
            background-color: #eee;
            background-clip:unset;
            border-radius: 7px;
            border: unset;
            &.active {
                background: linear-gradient(45deg, #E199D8, #B891E2, #D4AEE4);
            }
            &:hover{
                cursor: pointer;
            }
            + .dot{
                margin-left: 20px; 
            }
        }
    }
}