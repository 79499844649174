.Project{
    min-width: 250px;
    margin: 0 15px 0 15px;
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    * {
        pointer-events: none;
    }
    img{
        pointer-events: none;
        border-radius: 30px;
    }
    .title{
        font-style: normal;
        font-weight: 600;
        font-size: 36px !important;
        line-height: 50px;
        margin-top: 30px;
        margin-bottom: 10px;
        color: #6A3093;
    }
    .text{
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #546E7A;
    }
}