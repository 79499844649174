.Testimonials{
    height: 502px;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 180px;

    @media (max-width: 1024px) {
        height: unset;
        .Slider{
            height: 500px;
        }
    }
    
    .titleKey {
        margin-bottom: 60px;
    }
    .testimonialsList{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }
}