.navigation{
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(-100px);
    transition: all 900ms cubic-bezier(.9, 0, .33, 1);
}

.open .nav-links {
    transform: translateX(0px);
}

.nav-links .link {
    font-family: Matter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;

    text-align: center;
    
    margin-bottom: 15px;
    text-decoration: none;
    color: #263238;
    text-transform: uppercase;
    //transition: all 300ms cubic-bezier(.9, 0, .33, 1);
}

.nav-links .link:hover {
    background: -webkit-linear-gradient(39.32deg, #6A3093 3.13%, #A044FF 96.04%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}



