.ProjectModal{
    max-width: 1215px;

    @media (max-width: 1250px) {
        max-width: 964px;
    }

    @media (max-width: 1023.9px) {
        max-width: 708px;
        height: 1025px;
    }
    @media (max-width: 767.9px) {
        max-width: 450px;
        height: 675px;
    }

    @media (max-width: 489.9px) {
        max-width: 345px;
        height: 675px;
    }
    
    .closeButton{
        position: fixed;
        width: 35px;
        height: 35px;
        right: 29px;
        top: 30px;
        z-index: 2;
        &:hover{
            cursor: pointer;
        }
    } 
    .content{
        position: relative;
        display: flex;
        flex-direction: row;
        padding: 60px 40px 60px 60px;

        @media (max-width: 1023.9px) {
            flex-direction: column;
            padding: 40px 30px 38px 40px;
        }
        @media (max-width: 767.9px) {
            padding: 30px 20px 30px 30px;
            height: inherit;
        }
        
        .screen-block{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-right: 30px;

            @media (max-width: 1023.9px) {
                flex-direction: row;
                margin-right: 0;
            }
            @media (max-width: 767.9px) {
                flex-direction: column;
                align-self: flex-start;
            }
            
            .screen{
                box-shadow: 0px 15px 20px rgba(32, 9, 81, 0.15);
                border-radius: 30px;
                margin-bottom: 30px;
                @media (max-width: 1250px) {
                    max-width: 344px;
                }
                @media (max-width: 1023.9px) {
                    max-width: 552px;
                }
                @media (max-width: 767.9px) {
                    max-width: 359px;
                }
                @media (max-width: 489.9px) {
                    max-width: 251px;
                }
            }
            .technologies{
                @media (max-width: 1023.9px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 27px;
                }
                @media (max-width: 767.9px) {
                    flex-direction: row;
                    margin-left: 0;
                }
                .technologies-item{
                    box-shadow: 0px 15px 20px rgba(32, 9, 81, 0.15);
                    border-radius: 10px;
                }
                .technologies-item + .technologies-item{
                    margin-left: 15px;
                    @media (max-width: 1023.9px) {
                        margin-left: unset;
                        margin-top: 15px;
                    }
                    @media (max-width: 767.9px) {
                        margin-top: unset;
                        margin-left: 15px;
                    }
                    @media (max-width: 489.9px) {
                        margin-left: 10px;
                    }
                }
            }
        }
        .header{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 30px;

            position: absolute; 
            left: 626px;

            @media (max-width: 1250px) {
                left: 434px;
            }

            @media (max-width: 1023.9px) {
                position: unset;
                left: unset;
            }
            
            img {
                margin-right: 30px;
            }
            .right-logo-text{
                display: flex;
                flex-direction: column;
                font-size: 16px;
                line-height: 19px;
                color: #263238;
            }
        }
        .scroll-content{
            display: flex;
            flex-direction: row;
            @media (max-width: 1023.9px) {
                flex-direction: column;
            }
            @media (max-width: 767.9px) {
                overflow-y: auto;
                overflow-x: hidden;
                padding-right: 10px;
                
                &::-webkit-scrollbar-button, &::-webkit-scrollbar-track-piece, &::-webkit-scrollbar-corner, &::-webkit-resizer {
                    display: none;
                }

                &::-webkit-scrollbar-track
                {
                    background-color: transparent;
                    background-image: url("scroll-bg.png");
                    background-repeat: repeat-y;
                    background-size: contain;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar
                {
                    width: 10px;
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb
                {
                    background: linear-gradient(44.12deg, #CD6FC1 -9.02%, #9765CD 55.32%, #BB87D1 121.02%);
                    border-radius: 10px;
                }
            }
        }
        .text-body{
            height: 563px;
            font-size: 24px;
            line-height: 36px;
            
            margin-top: 87px;

            @media (max-width: 1023.9px) {
                margin-top: 10px;
                height: 289px;
            }
            @media (max-width: 767.9px) {
                height: 355px;
                font-size: 20px;
                line-height: 27px;
                margin-top: 20px;
            }

            .project-description{
                font-style: normal;
                font-weight: 600;
                line-height: 29px;
                text-transform: uppercase;
                margin-bottom: 40px;
                color: #263238;
            }
            .project-delivered{
                font-weight: 600;
                line-height: 20px;
                margin: 30px 0;
                color: #263238;
            }

            @media (min-width: 767.9px) {
                overflow-y: auto;
                
                &::-webkit-scrollbar-button, &::-webkit-scrollbar-track-piece, &::-webkit-scrollbar-corner, &::-webkit-resizer {
                    display: none;
                }

                &::-webkit-scrollbar-track
                {
                    background-color: transparent;
                    background-image: url("scroll-bg.png");
                    background-repeat: repeat-y;
                    background-size: contain;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar
                {
                    width: 10px;
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb
                {
                    background: linear-gradient(44.12deg, #CD6FC1 -9.02%, #9765CD 55.32%, #BB87D1 121.02%);
                    border-radius: 10px;
                }
            }
        }
    }
}