.TeamMember{
    position: absolute;
    z-index: 1;
    &:hover {
        z-index: 2;
        .tooltip-text {
            visibility: visible;
        }
    }
    
    img{
        border-radius: 500px;
        filter: drop-shadow(0px 5px 30px rgba(32, 9, 81, 0.3));
    }
    
    .tooltip-text {
        top: 100%;
        left: 50%;
        margin-top: 3px;
        
        visibility: hidden;
        background-color: white;
        color: black;
        text-align: center;
        box-shadow: 0px 15px 30px rgba(32, 9, 81, 0.3);
        border-radius: 5px;

        /* Position the tooltip text - see examples below! */
        position: absolute;

        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        padding: 11px 45px 9px 45px;

        @media (max-width: 1024px) {
            font-size: 12.5px;
            line-height: 14px;
            padding: 9.8px 40px 8px 40px;
        }
        
        .name{
            color: #263238;
            font-weight: bold;
            white-space: nowrap;
        }
        .job-country{
            color: #546E7A;
            white-space: nowrap;
        }
    }
    .tooltip-text::after {
        content: " ";
        position: absolute;
        bottom: 100%;  /* At the top of the tooltip */
        left: 50%;
        margin-left: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent white transparent;
    }
}