.comics{
  background: url("bg.jpg") repeat-y top /cover;
  overflow: hidden;
  padding-bottom: 130px;
  position: relative;
}

.previews{
  padding: 0 70px;
  overflow: hidden;
}

.clouds{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
  width: 100%;
}

.line {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

#tooltip{
  opacity: 0;
}

.rocket{
  transition: all .15s linear;
  width: 400px;
  &-tooltip{
    position: absolute;
    top: 0;
  }
  &-arrow{
    transition: all .15s linear;
    opacity: 0;
  }
  &-text{
    padding: 21px 35px;
    border-radius: 20px;
    transition: all .15s linear;
  }
  &-chapter{
    font: 700 24px /18px 'Space Mono';
    color: #fff;
    margin: 0 0 20px;
  }
  &-name{
    font: 400 28px /40px 'Space Mono';
    color: #fff;
    margin: 0;
  }
}

.Sidenav{
  position: fixed;
  right: 90px;
  color: #fff;
  z-index: 5;
  min-height: 422px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: url("sidebar-line.svg") repeat-y 57% 0;
  &__item{
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .15s all linear;
    &.active{
      .chapter{
        opacity: 1;
        visibility: visible;
      }
      .dot{
        width: 30px;
        height: 30px;
        span{
          width: 20px;
          height: 20px;
          border: 5px solid #82848c;
        }
      }
    }
    .chapter{
      position: absolute;
      min-width: 67px;
      font: 400 12px /18px 'Space Mono';
      top: 50%;
      left: -75px;
      transform: translate(0, -50%);
      opacity: 0;
      visibility: hidden;
      transition: opacity .15s linear, visibility .25s linear;
    }
    .dot{
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, .3);
      border: .5px solid rgba(255, 255, 255, .5);
      transition: .15s all linear;
      span{
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #fff;
        transition: .15s all linear;
      }
    }
  }
  img{
    align-self: flex-end;
    max-width: 57px;
  }
}

.path-wrapper{
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 65%;
  z-index: 10;
  overflow: visible;
  #rocket{
    pointer-events: auto;
  }
  transform: translate(-50%, 0);
  svg{
    overflow: visible;
  }
}

.blackHole {
  max-width: 100%;
}

@media (min-width: 2600px) {
  .path-wrapper{
    left: 50%;
  }
  .Sidenav{
    &__item{
      .chapter{
        font-size: 20px;
        left: -130px;
      }
      &.active{
        .dot{
          width: 40px;
          height: 40px;
          span{
            width: 25px;
            height: 25px;
            border: 5px solid #82848c;
          }
        }
      }
      .dot{
        margin-left: 10px;
        width: 20px;
        height: 20px;
        span{
          width: 10px;
          height: 10px;
        }
      }
    }
    img{
      align-self: flex-end;
      max-width: 57px;
    }
  }
  .razor{
    height: 117px;
  }
  .rocket{
    width: 600px;
  }
}

@media(max-width: 1600px){
  .path-wrapper{
    display: none;
  }
}

@media(max-width: 1200px) {
  .comics > div:nth-child(2){
    top: 0 !important;
  }
  .Sidenav{
    right: 30px;
  }
}

@media (max-width: 600px) {
  .comics{
    padding-bottom: 30px;
    background-image: url("bg-mobile.jpg");
  }
  .comics > div:first-child{
    top: -4%;
   img{
     height: 40px;
   }
  }
  .Sidenav{
    right: 15px;
    &__item{
      &.active {
        .dot{
          width: 20px;
          height: 20px;
          span{
            width: 10px;
            height: 10px;
            border-width: 2px;
          }
        }
      }
      .chapter{
        font-size: 11px;
      }
    }
  }
}