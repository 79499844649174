.Header {
    top: 10px;
    position: fixed;
    width: 100%;
    padding: 20px 0;
    z-index: 10;

    .content{
        max-width: unset;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 1440px) {
            padding: 0 30px;
        }
        @media (max-width: 480px) {
            padding: 0 15px;
        }
    }

    .logo{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .logo-img{
        padding-right: 10px;
        border-right: 1px solid gray;
        height: 43px;
    }
    .logo-text{
        width: 300px;
        height: 46px;
        font-family: 'Space Mono', monospace;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        /* or 129% */

        letter-spacing: 0.2em;

        /* secondary font color */

        color: #546E7A;

        order: 2;
        flex-grow: 0;
        margin: 0px 10px;
    }
}

@media (max-width: 767px) {
    .Header .logo{
        flex-direction: column;
        align-items: flex-start;
    }
}