.IndustriesModal{
    max-width: 768px;
    max-height: 675px;
    
    @media (max-width: 768px) {
        max-width: 450px;
    }
    @media (max-width: 470px) {
        max-width: 345px;
    }
     
    .closeButton{
        position: fixed;
        width: 35px;
        height: 35px;
        right: 29px;
        top: 30px;
        z-index: 2;
        &:hover{
            cursor: pointer;
        }
    } 
    .content{
        display: flex;
        flex-direction: column;
        padding: 60px;
        max-height: 675px;

        @media (max-width: 768px) {
            padding: 30px;
        }
        
        .header{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 30px;

            font-weight: 600;
            font-size: 26px;
            line-height: 30px;
            color: #263238;

            @media (max-width: 470px) {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 15px;
                img{
                    margin-bottom: 15px;
                }
            }

            img{
                width: 100px;
                height: 100px;
                padding: 23px 22px 22px 23px;
                background: linear-gradient(135deg, #FFFFFF 0%, #FFFFFF 54.69%, #E3E3E3 100%);
                box-shadow: 0px 15px 20px rgba(32, 9, 81, 0.15);
                border-radius: 5px;
                margin-right: 30px;
            }
        
        }
        .scroll-content{
            font-size: 20px;
            line-height: 28px;
            
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 10px;
            white-space: pre-wrap;

            &::-webkit-scrollbar-button, &::-webkit-scrollbar-track-piece, &::-webkit-scrollbar-corner, &::-webkit-resizer {
                display: none;
            }

            &::-webkit-scrollbar-track
            {
                background-color: transparent;
                background-image: url("scroll-bg.png");
                background-repeat: repeat-y;
                background-size: contain;
                border-radius: 10px;
            }

            &::-webkit-scrollbar
            {
                width: 10px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb
            {
                background: linear-gradient(44.12deg, #CD6FC1 -9.02%, #9765CD 55.32%, #BB87D1 121.02%);
                border-radius: 10px;
            }
        }
    }
}