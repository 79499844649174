.comics > .Header {
    position: relative;
    top: 0;
    margin-bottom: 30px;
    z-index: 50;
    .content{
        justify-content: flex-start;
        padding: 0 100px;
    }
    .logo-img{
        border: none;
    }
    .comics__logo{
        &__img{
            max-width: 100%;
            margin: 0 auto;
        }
    }
}

.Header.comics__header{
    .content{
        justify-content: center;
    }
}

@media (min-width: 2600px) {
    .Header{
        padding: 60px 0 0;
        .content{
            align-items: flex-start;
        }
        .logo-img{
            height: 84px;
        }
        .comics__logo__img{
            height: 272px;
        }
    }
}

@media(max-width: 1600px){
  .comics   .Header{
        padding: 30px 0 0;
        .content{
            align-items: flex-start;
        }
        .logo-img{
            height: 34px;
        }
        .comics__logo__img{
            height: 110px;
        }
    }
}

@media (max-width: 1200px) {
    .comics .Header{
        .content{
            align-items: center;
            padding: 0 30px;
        }
        .logo-img{
            height: 28px;
            padding: 0;
        }
        .comics__logo__img{
            height: 65px;
        }
    }
}

@media (max-width: 600px) {
    .comics .Header{
        .content{
            padding: 0 15px;
        }
        .logo-img{
            height: 20px;
        }
        .comics__logo__img{
            height: 45px;
        }
    }
}