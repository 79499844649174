.WeAre{
    max-width: 669px;
    .forTitle{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;

        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
        color: #263238;
        
        max-width: unset;
        
        img{
            width: 100px;
            height: 100px;
            padding: 23px 22px 22px 23px;
            background: linear-gradient(135deg, #FFFFFF 0%, #FFFFFF 54.69%, #E3E3E3 100%);
            box-shadow: 0px 15px 20px rgba(32, 9, 81, 0.15);
            border-radius: 5px;
            margin-right: 30px;
        }
    }
    .text{
        font-size: 20px;
        line-height: 28px;
        color: #546E7A;

        @media (max-width: 1440px) {
            font-size: 18px;
            line-height: 26px;
        }
        @media (max-width: 450px) {
            font-size: 16px;
            line-height: 23px;
        }
    }
}