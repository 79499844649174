html{
    scroll-behavior: smooth;
}

*{
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: Matter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen' ,
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    button:focus, input:focus, select:focus {
        border: none !important;
        outline-offset: 1px !important;
        outline: 1px solid #b871f1 !important;
    }
    
    textarea:focus{
        border-color: white !important;
        outline-offset: 1px !important;
        outline: 1px solid #b871f1 !important;
    }

    input:required, textarea:required, select:required {
        border: 1px solid #FF0000 !important;
        outline-offset: 1px !important;
        outline: none !important;
    }
    input:required[type='checkbox']{
        -webkit-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
        -moz-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
        box-shadow: 0px 0px 0px 1px rgba(255,0,0,1);
    }

    &.modal-active {
        overflow: hidden;
        .modal-bg{
            animation: scaleBack .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
        .ReactModal__Overlay {
            animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
            .ReactModal__Content {
                opacity:0;
                animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
            }
        }
        
    }
    &.modal-inactive{
        .modal-bg{
            animation: scaleForward .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
        .ReactModalPortal{
            //animation: quickScaleDown 0s .5s linear forwards;
            .ReactModal__Overlay {
                animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
                .ReactModal__Content {
                    animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
                }
            }
        }
    }    
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}









@keyframes fadeIn {
    0% {
        background:rgba(255,255,255,.0);
    }
    100% {
        background:rgba(255,255,255,.7);
    }
}

@keyframes fadeOut {
    0% {
        background:rgba(255,255,255,.7);
    }
    100% {
        background:rgba(255,255,255,.0);
    }
}

@keyframes scaleUp {
    0% {
        transform:scale(.8) translateY(1000px);
        opacity:0;
    }
    100% {
        transform:scale(1) translateY(0px);
        opacity:1;
    }
}

@keyframes scaleDown {
    0% {
        transform:scale(1) translateY(0px);
        opacity:1;
    }
    100% {
        transform:scale(.8) translateY(1000px);
        opacity:0;
    }
}

@keyframes scaleBack {
    0% {
        transform:scale(1);
    }
    100% {
        transform:scale(.85);
    }
}

@keyframes scaleForward {
    0% {
        transform:scale(.85);
    }
    100% {
        transform:scale(1);
    }
}

@keyframes quickScaleDown {
    0% {
        transform:scale(1);
    }
    99.9% {
        transform:scale(1);
    }
    100% {
        transform:scale(0);
    }
}