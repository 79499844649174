.Portfolio{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 90px;
        
        .titleKey{
            color: #F0F2F6;
        }
        .title{
            color: #FFFFFF;
            @media (max-width: 450px) {
                text-align: center;
            }
        }
        .underTitleText{
            text-align: center;
            color: #F0F2F6;

            @media (max-width: 768px) {
                line-height: 27px;
            }
        }
    }
    .InfinityCarousel{
        width: calc(100% + 2*280px);
    }
}