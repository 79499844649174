.SideMenuFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(-100px);
    transition: all 900ms cubic-bezier(.9, 0, .33, 1);

    color: #546E7A;

    @media (max-width: 768px) {
        padding: 0 10px 30px 10px;
        width: auto;
    }

    a {
        color: #546E7A;
        text-decoration: none;
    }
    .icon-container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;

        background: linear-gradient(135deg, #FFFFFF 0%, #FFFFFF 54.69%, #E3E3E3 100%);
        box-shadow: 0px 4px 10px rgba(32, 9, 81, 0.25);
        border-radius: 5px;

        img{
            width: 20px;
            height: 20px;
        }
    }
    .addresses{
        display: flex;
        flex-direction: row;
        width: 520px;
        font-family: Matter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 15px 0;
        &.main{
            width: 610px;
            .contact-info + .contact-info{
                margin-left: 30px;
                padding-left: 30px;
            }
        }
        .address{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            .no-wrap{
                white-space: nowrap;
            }
        }
        .phone,
        .email{
            white-space: nowrap;
            margin-top: 10px;
        }
        
        .contact-info{
            flex: 1 1 auto;
            & + .contact-info{
                margin-left: 20px;
                padding-left: 20px;
                border-left: 1px solid white;
            }

            @media (max-width: 480px) {
                margin-right: 15px;
            }
        }
        
        .title{
            display: flex;
            flex-direction: row;
            align-items: center;
            align-self: center;
            color: #263238;
            font-family: Matter, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 21px;
            margin-bottom: 10px;
            .icon-container{
                margin-right: 10px;
            }
        }
    }
    
    .contacts{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 234px;
        flex-wrap: wrap;
        font-size: 16px;
        line-height: 19px;
        @media (max-width: 480px) {
            max-width: unset;
        }
        
        .item{
            display: flex;
            flex-direction: row;
            align-items: center;
            
            .icon + a{
                margin-left: 15px;
            }
            .icon{
                width: 36px;
                height: 36px;
                padding: 8px;
                
                background: linear-gradient(135deg, #FFFFFF 0%, #FFFFFF 54.69%, #E3E3E3 100%);
                box-shadow: 0px 4px 10px rgba(32, 9, 81, 0.25);
                border-radius: 5px;
                
                img{
                    width: 20px;
                    height: 20px;
                }
            }
            span{
                white-space: nowrap;
            }
        }
    }
    .general-email{
        display: flex;
        justify-content: center;
        align-items: center; 
        margin: 20px 0;
        font-size: 16px;
        line-height: 19px;
    }

    @media (max-width: 698px) {
        width: auto;
        .addresses.main{
            width: auto;
            flex-direction: column;
            .contact-info + .contact-info{
                margin: 15px 0 0 0;
                padding: 15px 0 0 0;
                border-left: none;
                border-top: 1px solid white;
            }
            .address{
                flex-direction: row;
            }
        }
        .em-logo{
            width: 160px;
        }
    }
    @media (max-width: 570px) {
        width: auto;
        padding: 0 30px 20px;
        .addresses.side-menu{
            width: 100%;
            flex-direction: column;
            margin-top: 0;
            .contact-info{
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                .title{
                    margin-bottom: 0;
                }
                .phone{
                    margin-top: 0;
                }
                .address{
                    flex-direction: row;
                }
            }
            .contact-info + .contact-info{
                margin: 10px 0 0 0;
                padding: 10px 0 0 0;
                border-left: none;
                border-top: 1px solid white;
            }
            
        }
    }
}

.open .SideMenuFooter{
    transform: translateX(0px);
}