.OurIndustries{
    z-index: 2;
    margin-bottom: 120px;

    @media (max-width: 450px) {
        margin-bottom: 60px;
    }
    
    .content {
        max-width: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .titleKey{
        margin-top: 50px;
        margin-bottom: 90px;
    }

    .InfinityCarouselStaticWidth{
        overflow: unset;
        .carousel-track{
            padding: 0 30px;
            
            @media (max-width: 768px) {
                padding: 0;
            }
        }
    }
}