.IndustriesItem{
    z-index: 2;
    flex: auto;
    width: 448px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 15px 30px 15px;
    padding: 30px;
    background: #FFFFFF;
    
    border: 1px solid #F0F2F6;
    box-sizing: border-box;
    box-shadow: 0px 30px 60px rgba(32, 9, 81, 0.3);
    border-radius: 30px;

    @media (max-width: 1700px) {
        width: 393px;
    }
    @media (max-width: 1440px) {
        width: 407px;
        margin: 0 0 30px 0;
        + .IndustriesItem{
            margin-left: 30px;
        }
    }
    @media (max-width: 768px) {
        width: 400px;
        
    }
    @media (max-width: 450px) {
        width: 330px;
        height: 429px;
        + .IndustriesItem{
            margin-left: 15px;
        }
    }
    
    .forTitle{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;

        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
        color: #263238;
        
        @media (max-width: 450px) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 15px;
            img{
                margin-bottom: 15px;
            }
        }

        img{
            width: 100px;
            height: 100px;
            padding: 23px 22px 22px 23px;
            background: linear-gradient(135deg, #FFFFFF 0%, #FFFFFF 54.69%, #E3E3E3 100%);
            box-shadow: 0px 15px 20px rgba(32, 9, 81, 0.15);
            border-radius: 5px;
            margin-right: 30px;
        }
    }
    .text{
        font-size: 20px;
        line-height: 28px;
        color: #546E7A;
    }
    .arrow{
        align-self: end;
        margin-top: 10px;
        padding: 5px;
        height: 30px;
        width: 34px;
        cursor: pointer;
        transition: all 600ms cubic-bezier(.8, 0, .33, 1);
    }
}