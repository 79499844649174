.IndustriesList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding: 0 100px;
    max-width: 3300px;

    @media (min-width: 2140px) and (max-width: 2630px){
        max-width: 2100px;
    }
    @media (max-width: 1700px) {
        padding: 0 50px;
    }
    @media (max-width: 1440px) {
        flex-wrap: unset;
        padding: 0;
    }
}