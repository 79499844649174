.Map{
    position: relative;
    height: 400px;
    width: 668px;
    background-image: url(worlddot.png);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 768px) {
        width: 450px;
        height: 269px;
    }
    @media (max-width: 450px) {
        transform: scale(.75);
    }

    @keyframes blinking{
        0% {
            outline-width: 0;
        }
        100% {
            outline-width: 10px;
        }
    }
    
    .country{
        outline-style: solid;
        border-radius: 100px;
        position: absolute;
    }
    
    .belarus{
        top: 105px;
        left: 400px;
        @media (max-width: 768px) {
            top: 65px;
            left: 270px;
        }
        outline-color: #FF990080;
        animation: blinking 1s alternate ease-in-out infinite;
    }
    .canada{
        top: 100px;
        left: 120px;
        @media (max-width: 768px) {
            top: 60px;
            left: 80px;
        }
        outline-color: #FE6C4480;
        animation: blinking 1.1s alternate ease-in-out infinite;
    }
    .germany{
        top: 125px;
        left: 370px;
        @media (max-width: 768px) {
            top: 75px;
            left: 250px;
        }
        outline-color: #8EAE6480;
        animation: blinking 1.2s alternate ease-in-out infinite;
    }
    .israel{
        top: 190px;
        left: 365px;
        @media (max-width: 768px) {
            top: 125px;
            left: 250px;
        }
        outline-color: #F9515180;
        animation: blinking 1.3s alternate ease-in-out infinite;
    }
    .russia{
        top: 100px;
        left: 450px;
        @media (max-width: 768px) {
            top: 60px;
            left: 300px;
        }
        outline-color: #9A92FF80;
        animation: blinking 1.15s alternate ease-in-out infinite;
    }
    .united-kingdom{
        top: 130px;
        left: 300px;
        @media (max-width: 768px) {
            top: 80px;
            left: 200px;
        }
        outline-color: #12CCE580;
        animation: blinking 1.25s alternate ease-in-out infinite;
    }
    .united-states{
        top: 150px;
        left: 160px;
        @media (max-width: 768px) {
            top: 100px;
            left: 100px;
        }
        outline-color: #FFC10780;
        animation: blinking 1.05s alternate ease-in-out infinite;
    }
}