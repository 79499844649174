.Alert{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 70px;
    bottom: 0;
    background: rgba(255, 255, 255, 0.75);
    box-shadow: 0px -15px 30px rgba(32, 9, 81, 0.15);
    backdrop-filter: blur(100px);
    transform: translateY(70px);
    transition: all 800ms cubic-bezier(.8, 0, .33, 1);
    opacity: 0;
    
    .closeButton{
        width: 20px;
        height: 20px;
        z-index: 2;
        position: absolute;
        right: 20px;
        &:hover{
            cursor: pointer;
        }
    }
    
    &.open{
        transform: translateY(0px);
        opacity: 1;
    }
}