.AnimatedImage {
    margin-top: 163px;
    margin-left: 45px;

    @media (max-width: 1024px) {
        margin: 0;
    }
    
    .back{
        width: 429px;
        height: 705px;
        background: linear-gradient(212.59deg, #FCBE77 2.45%, #C754BB 53.82%, #5156CD 102.52%);
        box-shadow: 0px 30px 60px rgba(32, 9, 81, 0.15);
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1440px) {
            width: 312px;
            height: 513px;
        }
        @media (max-width: 768px) {
            width: 242px;
            height: 397px;
        }
        @media (max-width: 450px) {
            width: 218px;
            height: 359px;
        }

        .portfolio-link{
            margin: auto;

            @media (max-width: 1440px) {
                svg{
                    width: 185px;
                    height: 185px;
                }
            }
            @media (max-width: 768px) {
                svg{
                    width: 143px;
                    height: 143px;
                }
            }
            @media (max-width: 450px) {
                svg{
                    width: 129px;
                    height: 129px;
                }
            }
        }
        @keyframes rotate {
            from { transform: rotate(360deg); }
            to { transform: rotate(270deg); }
        }
        @keyframes rotateBack {
            from { transform: rotate(270deg); }
            to { transform: rotate(360deg); }
        }
        #text{
            transform-origin: center;
        }
        .portfolio-link.hovered #text{
            animation-name: rotate;
            animation-duration: .5s;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
        .portfolio-link.unhovered #text{
            animation-name: rotateBack;
            animation-duration: .5s;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }

        
        .animated-image {
            width: 734px;
            bottom: 0;
            margin-left: -118px;
            margin-bottom: -178px;
            position: absolute;

            @media (max-width: 1440px) {
                width: 520px;
                margin-left: -90px;
                margin-bottom: -125px;
            }
            @media (max-width: 768px) {
                width: 403px;
                margin-left: -70px;
                margin-bottom: -95px;
            }
            @media (max-width: 768px) {
                width: 360px;
                margin-left: -40px;
                margin-bottom: -95px;
            }
        }

        @keyframes do-levitation {
            0% {
                transform: translate(0, 0);
            }
            100% {
                transform: translate(0, 1em);
            }
        }

        .levitate {
            animation: do-levitation 1.2s alternate ease-in-out infinite;
        }
    }
}
