.App {
  color: #263238;
  font-size: 26px;
  line-height: 40px;
  overflow-x: hidden;

  @media (max-width: 1440px) {
    font-size: 20px;
    line-height: 30px;
  }
  
  .jointIndAndPortfolioBG{
    background-image: url(IPBG.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 120px;
    @media (min-width: 2560px) {
      background-image: url(IPBG_bigger_2560.jpg);
      background-position: center;
    }
  }
  
  .content{
    position: relative;
    max-width: 1920px;
    padding: 0 100px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1440px) {
      padding: 0 63px;
    }
    @media (max-width: 768px) {
      padding: 0 30px;
    }
  }

  .titleKey{
    font-family: 'Space Mono', monospace;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #546E7A;
    margin-bottom: 30px;

    @media (max-width: 1440px) {
      font-size: 18px;
    }
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }
  .title{
    font-family: Matter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 74px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      margin-bottom: 15px;
      font-size: 39px;
      line-height: 52px;
    }

    &.gradient{
      background: -webkit-linear-gradient(250deg, #9765CD 40.13%, #CD6FC1 100.04%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .subtitle{
    font-family: Matter, sans-serif;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 30px;
    @media(max-width: 768px){
      margin-bottom: 15px;
    }
  }
}

.spaceholder{
  width: 100%;
  height: 104px;
  background: linear-gradient(240.3deg, #5156CD 13.04%, #C754BB 45.73%, #FCBE77 76.72%);
}

.Vika{
  position: fixed;
  z-index: 9;
  bottom: -50%;
  left: 50%;
  transform: translateX(-50%);
  transition: 1s bottom ease-in-out;
  &-bubble{
    position: absolute;
    top: 8%;
    right: 3%;
  }
  p, a {
    font: 400 16px /21px 'Space Mono';
    color: #000;
    margin: 0;
    height: auto;
  }
  a{
    display: block;
    text-decoration: underline;
    color: #0077B5;
    max-width: 140px;
  }
  @media(max-width: 400px) {
    width: 85%;
    img{
      max-width: 100%;
    }
    .Vika-bubble{
      right: 1%;
      p, a {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}