.SideMenu {
    position: fixed;
    z-index: -1;
    opacity: 0;
    top: 0;
    overflow: hidden;
    right: 0;
    transform: translateX(580px);
    height: 100%;
    width: 580px;
    transition: all 800ms cubic-bezier(.8, 0, .33, 1);
    border-radius: 0% 0% 100% 50%;

    box-shadow: 0px 30px 60px rgba(32, 9, 81, 0.15);
    background: hsla(0,0%,100%,.7);

    backdrop-filter: blur(20px);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 768px) {
        width: 100%;
    }
    
    .SideMenuFooter{
        position: unset;
    }
    
    .grogu{
        position: absolute;
        width: 393px;
        height: 600px;
        bottom: 0;
        right: -393px;
        background-image: url(grogu.png);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 480px) {
            width: 262px;
            height: 400px;
            right: -262px;
        }
    }
}

@keyframes grogu-look {
    0% {transform: translateX(0);}
    50% {transform: translateX(-393px);}
    100% {transform: translateX(0px);}
}

@keyframes grogu-look-480 {
    0% {transform: translateX(0);}
    50% {transform: translateX(-262px);}
    100% {transform: translateX(0px);}
}

.SideMenu.open {
    z-index: 10;
    opacity: 1;
    transform: translateX(0px);
    border-radius: 0% 0% 0% 0%;
    background: rgba(255, 255, 255, 0.6);

    .grogu{
        animation-name: grogu-look;
        animation-duration: 2s;
    }
    @media (max-width: 480px) {
        .grogu{
            animation-name: grogu-look-480;
            animation-duration: 2s;
        }
    }
}

.closeMenu{
    z-index: 9;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    display: none;
}

.SideMenu.open + .closeMenu{
    display: block;
}
