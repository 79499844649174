.InfinityCarouselStaticWidth {
    overflow: hidden;
    height: max-content;
    width: 100%;
    padding-bottom: 90px;
    
    .carousel-track{
        display: flex;
        flex-direction: row;
    }
}