.OurTeam{
    height: 1230px;
    margin-bottom: 180px;
    @media (max-width: 768px) {
        height: unset;
    }
    .content{
        max-width: 1381px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title{
            text-align: center;
        }
        .underTitleText{
            color: #546E7A;
            text-align: center;
            margin-bottom: 30px;
            @media (max-width: 768px) {
                line-height: 27px;
                margin-bottom: 15px;
            }
        }
    }
    
}