.Input {
    position: relative;
    width: 100%;
    font-style: normal !important;
    font-weight: normal !important;
    line-height: 32px !important;
    font-size: 20px !important;
    
    input{
        width: 100%;
        font-style: normal;
        font-weight: normal;
        line-height: 32px;
        font-size: 20px;
        background: #FFFFFF;
        border: 1px solid #CED1E7;
        box-sizing: border-box;
        border-radius: 30px;
        color: #9CA4B5;
        padding: 18px 30px;
        resize: none;
        font-family: Matter, -apple-system, BlinkMacSystemFont, 'Segoe UI', serif !important;
    }
    .placeholder{
        position: absolute;
        pointer-events: none;
        top: 18px;
        left: 30px;
        margin: auto;
        color: #9CA4B5;
    }
}