.Hamburger {
    --color: #000; /*-----set bars color*/
    --bg-color: #fff00; /*--set background color*/
    height: 37.5px; /*-----set icon size */
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    border-radius: 15%;
    background: var(--bg-color);
    position: relative;
    overflow: hidden;
    z-index: 20;
    cursor: pointer;

    span {
        display: block;
        width: 70%;
        height: 8%;
        border-radius: 100vh;
        background: var(--color);
        position: absolute;
        transition: 0.5s;
    }

    span:nth-child(1) {
        top: 20%;
    }

    &.active span:nth-child(1) {
        top: 50%;
        transform: translateY(-50%) rotate(225deg);
        transition-delay: 0.25s;
    }

    span:nth-child(2) {
        bottom: 20%;
    }

    &.active span:nth-child(2) {
        bottom: 50%;
        transform: translateY(50%) rotate(-225deg);
        transition-delay: 0.25s;
    }

    &.active span:nth-child(3) {
        transform: translateX(150%);
    }
}