.FirstScreen {  
    background-image: url(main_bg_2560.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 139px;

    @media (min-width: 2560px) {
        background-image: url(main_bg_bigger_2560.jpg);
    }
    
    #who-do-we-serve{
        position: absolute;
        bottom: 0;
    }
    #turn-key-services{
        position: absolute;
        top: 0;
    }
    .content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 1628px;

        @media (max-width: 1440px) {
            max-width: 1200px;
        }
        
        @media (max-width: 1024px) {
            flex-wrap: wrap;
            justify-content: center;
        }
        
        .mainText{
            max-width: 700px;
            padding-top: 223px;
            margin-bottom: 60px;

            @media (max-width: 1440px) {
                padding-top: 163px;
                max-width: 600px;
            }

            .title{
                margin-bottom: 0;
                font-size: 84px;
                line-height: 90px;

                @media (max-width: 1920px) {
                    font-size: 80px;
                    line-height: 86px;
                }

                @media (max-width: 1440px) {
                    font-size: 60px;
                    line-height: 60px;
                }

                @media (max-width: 768px) {
                    font-size: 39px;
                    line-height: 52px;
                }
            }
            .aboutUs{
                font-size: 24px;
                line-height: 36px;
                margin: 60px 0;

                @media (max-width: 1550px) {
                    max-width: 600px;
                }

                @media (max-width: 1440px) {
                    font-size: 20px;
                    line-height: 30px;
                    margin: 30px 0;
                }
                @media (max-width: 1024px) {
                    margin: 30px 0;
                }
                @media (max-width: 768px) {
                    line-height: 27px;
                    margin: 15px 0;
                }
            }
        }
        .em-logo {
            display: block;
            margin-bottom: -120px;

            @media (max-width: 1024px) {
                margin: 0;
                img {
                    display: block;
                    margin: 0 auto;
                    width: 124px;
                }
            }
        }
    }
}
