.Logo-container{
    height: 150px;
    padding-bottom: 60px;
    user-select: none;
}

.Logo{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 90px;
    background: #FFFFFF;
    box-shadow: 0px 15px 30px rgba(32, 9, 81, 0.15);
    border-radius: 15px;
    margin: 0 15px;

    @media (max-width: 768px) {
        width: 190px;
    }
    
    img{
        max-width: 180px;
        max-height: 80px;
        pointer-events: none;
    }
}